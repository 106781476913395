import React from 'react'
import { Helmet } from 'react-helmet'
import {
  getStorageItem,
  removeStorageItem,
} from '@klickmarketing/react-components'
import { navigate } from 'gatsby'

export default function LogoutSuccess() {
  React.useEffect(() => {
    const redirectTo = getStorageItem('logoutReturnTo')
    removeStorageItem('logoutReturnTo')

    navigate(redirectTo || '/')
  }, [])

  return (
    <Helmet>
      <meta name="robots" content="noindex,follow" />
    </Helmet>
  )
}
